export const onRouteUpdate = () => {
  const GTM_DATALAYER_PUSH_DELAY = 50;
  if (process.env.NODE_ENV === `production` || String(process.env.GATSBY_GTM_ENABLED) === 'true') {
    setTimeout(() => {
      const data = window?.dataLayer;
      const eventName = `appRouteChange`;
      data?.push({ event: eventName });
    }, GTM_DATALAYER_PUSH_DELAY);
  }
};
