import { SyntheticEvent, useContext } from 'react';

import { AppContext } from 'context/AppProvider';

import Link from 'ui/util/Link';

import SiteNavDropdownLink from './SiteNavDropdownLink';

interface Props {
  children?: any;
  isCurrentSection?: boolean;
  isButton?: boolean;
  triggerModal?: boolean;
  isDropdown?: boolean;
  isCurrentUrl?: boolean;
  url?: string;
  items?: any;
  name?: string;
  dropdownToggleHandler?: (
    event: SyntheticEvent,
    state: boolean,
    preventDefaultEventHandling?: boolean
  ) => void;
  isDropdownLabel?: boolean;
  isSubNavItem?: boolean;
  linkClickHandler?: any;
  styling?: any;
  bold?: boolean;
}

const buttonStylingProps: any = {
  marginBottom: '0',
  fontSize: '0.8rem',
  letterSpacing: '0.1rem',
  color: 'white',
  borderColor: 'red',
  backgroundColor: 'red',
  outline: 'none',
  padding: '0.65rem 1.5rem',
  display: 'inline-block',
  fontWeight: 'bold',
  textAlign: 'center',
  verticalAlign: 'middle',
  userSelect: 'none',
  border: '1px solid transparent',
  lineHeight: '1.5',
  borderRadius: '0.25rem',
  width: ['100%', 'auto'],
  textDecoration: 'none',
  transition:
    'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  boxSizing: 'border-box',
  position: 'relative',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: 'white',
    color: 'redOrange',
    borderColor: 'redOrange',
    border: '1px solid',
    textAlign: 'center',
    verticalAlign: 'middle',
    userSelect: 'none',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  '::before': {
    content: '""',
  },
};

export const singleLinkStyleHoverProps: any = ({
  isCurrentSection = false,
}: {
  isCurrentSection?: boolean;
}) => ({
  marginBottom: ['1rem', null],
  '::before': {
    content: '""',
    borderBottom: '1px solid',
    borderBottomColor: 'redOrange',
    width: ['0px', isCurrentSection ? '100%' : '0%'],
    position: 'absolute',
    bottom: ['-10px', '-4px'],
    transition: 'width .3s ease-in-out',
  },
  ':hover::before': {
    width: '100%',
  },
});

export const singleLinkStyleProps: any = ({
  isCurrentSection = false,
  bold = true,
}: {
  isCurrentSection?: boolean;
  bold?: boolean;
}) => ({
  textTransform: 'uppercase',
  color: 'elephant',
  fontWeight: [bold ? '700 !important' : '500 !important'],
  lineHeight: '1',
  marginLeft: ['0', '1rem'],
  marginRight: ['0.4rem', '0.4rem'],
  fontSize: ['0.75rem', '0.8rem'],
  textDecoration: 'none',
  letterSpacing: '1px',
  transition: 'all .3s ease-in-out',
  position: 'relative',
  ':hover': {
    cursor: 'pointer',
  },
  ...singleLinkStyleHoverProps({ isCurrentSection }),
});

export const isAnchor = (url: string) => url === '#';

/* eslint-disable @typescript-eslint/no-empty-function */
export const SiteNavSingleLink = ({
  isCurrentSection = false,
  isButton = false,
  triggerModal = false,
  isDropdown = false,
  isDropdownLabel = false,
  url = '',
  name,
  dropdownToggleHandler = () => {},
  isSubNavItem = false,
  linkClickHandler = () => {},
  styling = {},
  bold = true,
}: Props) => {
  const { state } = useContext(AppContext);

  const triggerModalHandler = () => {
    state.site.siteModalIsOpen = !!!state.site.siteModalIsOpen;
    linkClickHandler();
  };

  return isDropdown || isSubNavItem ? (
    <SiteNavDropdownLink
      isCurrentSection={isCurrentSection}
      name={name}
      url={url}
      bold={bold}
      isDropdown={isDropdown}
      isSubNavItem={isSubNavItem}
      isDropdownLabel={isDropdownLabel}
      dropdownToggleHandler={dropdownToggleHandler}
      linkClickHandler={linkClickHandler}
      styling={styling}
    />
  ) : triggerModal ? (
    <div
      sx={{
        ...singleLinkStyleProps({ isCurrentSection, isSubNavItem }),
        ...singleLinkStyleHoverProps,
        ...(isButton ? buttonStylingProps : {}),
        ...styling,
      }}
      onClick={triggerModalHandler}>
      {name}
    </div>
  ) : (
    <Link
      styling={{
        ...singleLinkStyleProps({ isCurrentSection, isSubNavItem }),
        ...singleLinkStyleHoverProps,
        fontSize: '1rem',
        ...(isButton ? buttonStylingProps : {}),
        ...styling,
      }}
      to={url}
      onClick={linkClickHandler}>
      {name}
    </Link>
  );
};

export default SiteNavSingleLink;
