import { Box } from '@theme-ui/components';
import { ReactNode } from 'react';

import { getResponsiveColumnStyles, getResponsiveOffsetStyles } from 'lib/flexGrid';

import MemoizedComponent from 'ui/util/MemoizedComponent';

interface Props {
  columns?: (number | null)[];
  offsets?: (number | null)[];
  id?: string;
  props?: any;
  styling?: any;
  children?: ReactNode;
  disablePadding?: boolean;
}

export const Column = ({
  columns,
  offsets,
  children,
  styling,
  id,
  disablePadding = false,
  ...props
}: Props) => {
  const cols = columns ? getResponsiveColumnStyles(columns) : {};
  const offs = offsets ? getResponsiveOffsetStyles(offsets) : {};

  return (
    <Box
      sx={{
        paddingX: disablePadding ? 0 : '15px',
        ...cols,
        ...offs,
        ...styling,
      }}
      {...(id && { id })}
      {...props}>
      {children}
    </Box>
  );
};

export default MemoizedComponent(Column);
