import { Duration } from 'duration-converter';
import isEmpty from 'lodash/isEmpty';
export const NEWSLETTER_MODAL_COOKIE_EXPIRATION = new Duration('2 weeks').Seconds;
export const NEWSLETTER_MODAL_COOKIE_NAME = 'projectn95_newsletter_modal';

export const SITE_MODAL_COOKIE_EXPIRATION = new Duration(
  !isEmpty(process.env.GATSBY_MODAL_EXCLUSION_URIS)
    ? String(process.env.GATSBY_SITE_MODAL_COOKIE_EXPIRATION)
    : '2 weeks'
).Seconds;
export const SITE_MODAL_COOKIE_NAME = 'projectn95_site_modal';

export const SITE_ALERT_COOKIE_EXPIRATION = new Duration('1 day').Seconds;
export const SITE_ALERT_COOKIE_NAME = 'projectn95_site_alert';
