import { ThemeProvider } from '@theme-ui/theme-provider';
import { graphql, useStaticQuery } from 'gatsby';
import { cloneElement, Children, Suspense, useRef, useContext } from 'react';
import { Flags } from 'react-feature-flags';
import { Transition, TransitionGroup } from 'react-transition-group';

import { featureFlags } from 'constant/feature';
import { AppContext } from 'context/AppProvider';
import { isAlertExclusionPage } from 'lib/alert';
import { setupSiteLayoutData } from 'lib/siteLayout';
import themeConfig from 'style/theme';
import Footer from 'ui/component/Footer';
import Header from 'ui/component/Header';
import NewsletterSignup from 'ui/component/NewsletterSignup';
import SiteModal from 'ui/component/SiteModal';
import Alert from 'ui/module/Alert';

import SiteLayoutApp from './SiteLayoutApp';
import SiteLayoutIframe from './SiteLayoutIframe';
import SiteLayoutMain from './SiteLayoutMain';

const transitionStyles = {
  entering: {
    position: `fixed`,
    opacity: 0,
  },
  entered: {
    opacity: 1,
    transition: `opacity 100ms ease-in-out`,
  },
  exiting: {
    opacity: 0,
    transition: `opacity 250ms ease-in-out`,
  },
};

export const SiteLayout = ({ children, location, pageContext }: any) => {
  const data = useStaticQuery(graphql`
    query SiteLayout {
      contentfulKeyValueListModule(
        internalTitle: { regex: "Site/Copy/" }
        metadata: { tags: { elemMatch: { name: { eq: "sitewide-config" } } } }
      ) {
        id
        keyValueItems {
          key
          valueMarkdown {
            valueMarkdown
          }
        }
      }
      allContentfulSiteMetadata {
        edges {
          node {
            ...SiteMetadata
          }
        }
      }
      contentfulFooter(metadata: { tags: { elemMatch: { name: { eq: "sitewide-config" } } } }) {
        ...Footer
      }
      contentfulSiteModal(metadata: { tags: { elemMatch: { name: { eq: "sitewide-config" } } } }) {
        ...SiteModal
      }
      contentfulNewsletterSignup(
        metadata: { tags: { elemMatch: { name: { eq: "sitewide-config" } } } }
      ) {
        ...NewsletterSignup
      }
      contentfulAlertModule(
        metadata: { tags: { elemMatch: { name: { eq: "sitewide-config" } } } }
      ) {
        ...AlertModule
      }
      allContentfulNav {
        edges {
          node {
            id
            navId
            ...Nav
          }
        }
      }
      allContentfulPost(sort: { date: DESC }) {
        edges {
          node {
            ...Post
          }
        }
      }
      allAirtableStaff(
        filter: { data: { Full_Name: { ne: null }, LinkedIn_Profile: { ne: null } } }
      ) {
        edges {
          node {
            ...Staff
          }
        }
      }
      allAirtableRoles(
        filter: { data: { Role_Description: { ne: null }, Availability__FT_PT__: { ne: null } } }
      ) {
        group(field: { data: { Team: SELECT } }) {
          team: fieldValue
          edges {
            node {
              ...Roles
            }
          }
        }
      }
      allAirtableProducts(
        sort: { data: { Overall_Score__from_Armbrust_Test_Data__BASE_SYNC__: DESC } }
      ) {
        ...Products
      }
      contentfulImageListModule(internalTitle: { regex: "/Product Detail Attribute Options/" }) {
        id
        imageItems {
          internalTitle
          title
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          link
        }
      }
    }
  `);

  const state = useContext(AppContext);

  const { footer, siteNav, siteMetadata, newsletter, siteModal, siteAlert } = setupSiteLayoutData({
    data,
    state,
  });

  const currentUrl = location?.pathname;

  const nodeRef = useRef(null);
  const alertEnabled = siteAlert?.enabled && !isAlertExclusionPage();

  return pageContext?.pageType !== 'iframe' ? (
    <SiteLayoutApp>
      <ThemeProvider theme={themeConfig}>
        <SiteModal
          siteModal={siteModal}
          autoLaunch={featureFlags.some(
            ({ name, isActive }) => name === 'autolaunchModal' && isActive
          )}
        />
        <>
          <Alert
            content={siteAlert?.content}
            backgroundColor={siteAlert?.backgroundColor}
            enabled={alertEnabled}
          />
          <Header siteNav={siteNav} currentUrl={currentUrl} alertIsOpen={alertEnabled} />
          <SiteLayoutMain alertIsOpen={alertEnabled}>
            <TransitionGroup component={null}>
              <Transition
                nodeRef={nodeRef}
                key={currentUrl}
                timeout={{
                  enter: 750,
                  exit: 750,
                }}>
                {(status: string) => (
                  <div sx={{ maxWidth: ['100vw', null], ...transitionStyles[status] }}>
                    <Suspense fallback={<div />}>
                      {Children.map(children, (child: any) =>
                        cloneElement(child, { siteMetadata, innerRef: nodeRef })
                      )}
                    </Suspense>
                  </div>
                )}
              </Transition>
            </TransitionGroup>
          </SiteLayoutMain>
        </>
        <Flags authorizedFlags={['newsletter']}>
          <NewsletterSignup newsletter={newsletter} />
        </Flags>
        <Footer footer={footer} />
      </ThemeProvider>
    </SiteLayoutApp>
  ) : (
    <SiteLayoutIframe siteMetadata={siteMetadata}>{children}</SiteLayoutIframe>
  );
};

export default SiteLayout;
