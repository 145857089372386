import { getResponsiveColumnStyles } from 'lib/flexGrid';
import SiteLogo from 'ui/component/SiteLogo';

const cols = getResponsiveColumnStyles([3, 2]);

export const HeaderSiteLogo = () => (
  <div
    sx={{
      justifyContent: ['flex-end', 'flex-start'],
      display: 'flex',
      paddingX: ['unset', '15x'],
      width: '100%',
      zIndex: ['999999', 'inherit'],
      ...cols,
    }}>
    <SiteLogo />
  </div>
);

export default HeaderSiteLogo;
