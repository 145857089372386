export const shouldUpdateScroll = () => {
  setTimeout(
    () =>
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      }),
    1000
  );

  return false;
};
