import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';
import memoize from 'moize';

const teamListEdgesPath = 'allAirtableStaff.edges';
const teamListImageObjectPath = 'node.data';
const teamListImagePathSelector = (obj: any) => obj?.image?.localFiles[0]?.childImageSharp;

export const getTeamListFromData = memoize.deep((data: any) =>
  flow(
    get(teamListEdgesPath),
    map(get(teamListImageObjectPath)),
    map((obj: any) => ({ ...obj, image: teamListImagePathSelector(obj) }))
  )(data)
);
