import memoize from 'moize';
import slugify from 'slugify';

export const SITE_URL = String(process.env.GATSBY_SITE_URL);
export const SITE_BASE_DOMAIN = String(process.env.GATSBY_SITE_BASE_DOMAIN);

export const slugged = memoize.deep((txt: string) => slugify(txt, { lower: true, strict: true }));

export const getSluggedLink = memoize.deep(
  ({ prefix = '', item }: { prefix?: string; item: string }) => [prefix, slugged(item)].join('/')
);

export const sanitizeUrl = memoize.deep((url: string) => (!url || url === '#' ? '/' : url));

export const normalizeUriForwardSlashes = memoize.deep(({ uri }: { uri: string }) =>
  uri.replace(/^\/?/m, '/').replace(/\/?$/, '/')
);

export const getAbsoluteUrlForUri = memoize.deep((uri: string) =>
  SITE_URL.concat(normalizeUriForwardSlashes({ uri }))
);
