export const root = {
  fontFamily: 'body',
  lineHeight: 'body',
  fontWeight: 'body',
  ':focus-within': {
    scrollBehavior: 'smooth',
  },
};

export default root;
