export const elements = {
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: '400',
    fontSize: '100%',
    position: 'relative',
  },
  p: {
    fontSize: 1.1,
  },
  h1: {
    lineHeight: 1.3,
    marginTop: 2,
    marginBottom: 2,
    fontWeight: '100',
    fontSize: [2, 2.3],
  },
  h2: {
    fontWeight: 'normal',
    fontSize: 1.8,
  },
  h3: {
    fontWeight: 'normal',
    fontSize: 1.5,
  },
  h4: {
    fontWeight: 'normal',
    fontSize: 1.5,
    marginBottom: 1.5,
  },
  h5: {
    fontWeight: 'bold',
    fontSize: 1,
    marginTop: 2,
    letterSpacing: 0.1,
    textTransform: 'uppercase',
  },
  h6: {
    fontWeight: 'bold',
  },
};

export default elements;
