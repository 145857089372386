import Column from 'ui/component/Column';

import SiteNavSimple from 'ui/component/SiteNavSimple';
import MemoizedComponent from 'ui/util/MemoizedComponent';

interface Props {
  siteNav?: any;
  mobileNavIsOpen?: boolean;
  currentUrl?: string;
  alertIsOpen?: boolean;
}

export const HeaderSiteNav = ({ siteNav, mobileNavIsOpen, currentUrl, alertIsOpen }: Props) => (
  <Column
    columns={[0, 8.5]}
    sx={{
      textAlign: ['unset', 'right'],
      display: ['block', 'block'],
      paddingX: ['15px', 0],
    }}>
    <SiteNavSimple
      alertIsOpen={alertIsOpen}
      navConfig={siteNav}
      currentUrl={currentUrl}
      navIsOpen={mobileNavIsOpen}
      styling={{
        width: [mobileNavIsOpen ? '100vw' : '0px', 'inherit'],
        ':first-of-type': {
          padding: [mobileNavIsOpen ? '8rem 2rem 0 2rem' : '0px', 'inherit'],
        },
      }}
    />
  </Column>
);

export default MemoizedComponent(HeaderSiteNav);
