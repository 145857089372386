import { Container, Flex } from '@theme-ui/components';

import MemoizedComponent from 'ui/util/MemoizedComponent';

import HeaderPrimaryNav from './HeaderPrimaryNav';

interface Props {
  currentUrl?: string;
  siteNav?: any;

  setMobileNavIsOpen?: any;
  mobileNavIsOpen?: boolean;
  alertIsOpen?: boolean;
}

export const HeaderLayout = ({
  currentUrl,
  siteNav,
  setMobileNavIsOpen,
  mobileNavIsOpen,
  alertIsOpen,
}: Props) => (
  <Container
    sx={{
      padding: ['.5rem .5rem .5rem .5rem', ' 1.25rem 4rem .75rem 4rem'],
    }}>
    <Flex
      sx={{
        transition: 'unset',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}>
      <HeaderPrimaryNav
        alertIsOpen={alertIsOpen}
        currentUrl={currentUrl}
        siteNav={siteNav}
        setMobileNavIsOpen={setMobileNavIsOpen}
        mobileNavIsOpen={mobileNavIsOpen}
      />
    </Flex>
  </Container>
);

export default MemoizedComponent(HeaderLayout);
