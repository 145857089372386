import { toTheme } from '@theme-ui/typography';

import breakpoints from './breakpoints';
import colors from './colors';
import elements from './elements';
import root from './root';
import space from './space';
import typography from './typography';

export const baseConfig = {
  breakpoints,
  space,
  colors,
  styles: {
    ...root,
    ...elements,
  },
};

export const cfg = { ...baseConfig, ...toTheme(typography) };

export default cfg;
