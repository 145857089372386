import { Box } from '@theme-ui/components';
import isEmpty from 'lodash/isEmpty';
import { useContext } from 'react';
import { useSnapshot } from 'valtio';

import { AppContext } from 'context/AppProvider';

import SiteNavSimpleDropDown from './SiteNavSimpleDropDown';

interface Props {
  navConfig: { navSections: NavSectionConf[] };
  currentUrl?: string;
  navIsOpen?: boolean;
  styling?: any;
  alertIsOpen?: boolean;
}

export interface NavSectionConfigItem {
  name: string;
  url: string;
  bold?: boolean;
}

export interface NavSectionConf {
  section: string;
  name: string;
  url?: string;
  navItems?: NavSectionConfigItem[];
  isButton?: boolean;
  triggerModal?: boolean;
  styling?: any;
  description?: string;
}

export const SiteNavSimple = ({
  navConfig: { navSections },
  currentUrl = '',
  navIsOpen = false,
  styling = {},
  alertIsOpen = false,
}: Props) => {
  const { state } = useContext(AppContext);

  const linkClickHandler = (url: any) => {
    state.site.currentSection = url;
    state.site.navIsOpen = false;
  };

  const {
    site: { currentSection },
  } = useSnapshot(state);

  return (
    <Box
      sx={{
        marginTop: [0],
        display: ['flex', 'inherit'],
        alignItems: 'baseline',
        justifyContent: [null, 'space-evenly'],
        height: ['100vh', '1rem'],
        flexDirection: ['column', 'inherit'],
        position: ['absolute', 'relative'],
        top: [alertIsOpen ? '4rem' : '0', '0'],
        opacity: [navIsOpen ? '1' : '0', '1'],
        left: ['0', 'inherit'],
        width: ['100vw', '100%'],
        backgroundColor: ['white', 'unset'],
        padding: '0 2rem',
        transition: 'height 300ms ease-in-out 300ms, opacity 300ms ease-in-out 150ms',
        overflow: ['hidden', 'inherit'],
        ...styling,
      }}>
      {!isEmpty(navSections) &&
        navSections.map((navSectionConfig: NavSectionConf, idx: number) => (
          <SiteNavSimpleDropDown
            currentUrl={currentUrl}
            currentSection={currentSection}
            linkClickHandler={linkClickHandler}
            key={`nav-section-config-${idx}`}
            navSectionConfig={navSectionConfig}
          />
        ))}
    </Box>
  );
};

export default SiteNavSimple;
