import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import reduce from 'lodash/fp/reduce';

import memoize from 'moize';

import { getEdgesAndFlatten } from 'lib/selector';

export const getSiteMetaDataFromContentfulData = memoize.deep((data: any) =>
  getEdgesAndFlatten({
    data,
    key: 'allContentfulSiteMetadata',
  })
);

export const getSiteMetadataFromData = memoize.deep((data: any) =>
  flow(
    getSiteMetaDataFromContentfulData,
    get('metadata'),
    reduce(
      (acc: any, meta: any) => ({
        ...acc,
        [meta.key]: meta.value?.value ? meta.value?.value : meta.value,
      }),
      {}
    )
  )(data)
);

export const getSiteMetadataValueByKey = memoize.deep(
  ({ data, key }: { data: any; key: string }) => data?.[key]
);
