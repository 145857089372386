import { lazy, Suspense } from 'react';

import { NEWSLETTER_MODAL_COOKIE_NAME, NEWSLETTER_MODAL_COOKIE_EXPIRATION } from 'constant/cookie';

import { cookies } from 'lib/cookie';
import { hideModalOnCloseClick } from 'lib/modal';
import MemoizedComponent from 'ui/util/MemoizedComponent';

const NewsletterSignupContent = lazy(() => import('./NewsletterSignupContent'));

interface Props {
  hide?: boolean;
  heading: string;
  body?: string;
  submitStatus?: any;
  setSubmitStatus?: any;
  cta: string;
}

export const NewsletterSignupContainer = ({
  hide,
  heading,
  body,
  submitStatus,
  setSubmitStatus,
  cta,
}: Props) => (
  <Suspense fallback={<div />}>
    <div
      tabIndex={-1}
      sx={{
        backgroundColor: 'white',
        boxShadow: '-4px 2px 14px 1px rgb(0 0 0 / 10%)',
        textAlign: 'center',
        overflow: 'hidden',
        outline: '0',
        height: '100%',
        opacity: 1,
        transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}>
      <NewsletterSignupContent
        hideNewsletterHandler={() =>
          hideModalOnCloseClick({
            cookies,
            hide,
            modal_cookie_name: NEWSLETTER_MODAL_COOKIE_NAME,
            modal_cookie_expiration: NEWSLETTER_MODAL_COOKIE_EXPIRATION,
          })
        }
        cookies={cookies}
        hide={hide}
        heading={heading}
        body={body}
        submitStatus={submitStatus}
        submitStatusHandler={setSubmitStatus}
        cta={cta}
      />
    </div>
  </Suspense>
);

export default MemoizedComponent(NewsletterSignupContainer);
