import { useContext, ReactNode } from 'react';

import { useSnapshot } from 'valtio';

import { ALERT_DESKTOP_HEIGHT, ALERT_MOBILE_HEIGHT } from 'constant/alert';
import { AppContext } from 'context/AppProvider';

interface Props {
  props?: any;
  alertIsOpen?: boolean;
  children: ReactNode;
}

export const HeaderElement = ({ children, alertIsOpen = false, ...props }: Props) => {
  const { state } = useContext(AppContext);

  const {
    site: { searchIsOpen },
  } = useSnapshot(state);

  return (
    <header
      sx={{
        padding: [null, '.5rem 0'],
        position: 'fixed',
        width: '100vw',
        paddingTop: '1.5rem',
        paddingBottom: '0.5rem',
        top: '0',
        zIndex: '999',
        backgroundColor: searchIsOpen ? 'white' : 'rgba(255,255,255,0.9)',
        transform: [
          `translateY(${alertIsOpen ? ALERT_MOBILE_HEIGHT : 0})`,
          `translateY(${alertIsOpen ? ALERT_DESKTOP_HEIGHT : 0})`,
        ],
      }}
      {...props}>
      {children}
    </header>
  );
};

export default HeaderElement;
