import memoize from 'moize';

import { MODAL_EXCLUSION_URIS } from 'constant/modal';
import { isBrowser } from 'lib/browser';

export const MODAL_ON_CLOSE_TIMEOUT = 300;

export const isModalExclusionPage = () =>
  isBrowser &&
  (MODAL_EXCLUSION_URIS.includes(window?.location?.pathname) ||
    MODAL_EXCLUSION_URIS.some((uri: string) => window?.location?.pathname?.includes(uri)));

export const shouldHideModal = ({ modalSeen, hidden }: { modalSeen?: boolean; hidden?: boolean }) =>
  Boolean(isModalExclusionPage() || modalSeen || hidden);

export const hideModalOnCloseClick = memoize.deep(
  ({
    cookies,
    hide,
    modal_cookie_name,
    modal_cookie_expiration,
  }: {
    cookies: any;
    hide: any;
    modal_cookie_name: string;
    modal_cookie_expiration: number;
  }) => {
    hide(true);
    setTimeout(() => {
      cookies.set(modal_cookie_name, 1, {
        maxAge: modal_cookie_expiration,
      });
    }, MODAL_ON_CLOSE_TIMEOUT);
  }
);

export const checkForModalSeen = memoize.deep(
  ({ cookies, modal_cookie_name }: { cookies: any; modal_cookie_name: string }) => {
    return cookies.get(modal_cookie_name) === '1';
  }
);
