exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js?export=default" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=default" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-ui-template-page-page-tsx": () => import("./../../../src/ui/template/Page/Page.tsx?export=default" /* webpackChunkName: "component---src-ui-template-page-page-tsx" */),
  "component---src-ui-template-post-post-tsx": () => import("./../../../src/ui/template/Post/Post.tsx?export=default" /* webpackChunkName: "component---src-ui-template-post-post-tsx" */)
}



exports.head = {
  "component---src-ui-template-page-page-tsx": () => import("./../../../src/ui/template/Page/Page.tsx?export=head" /* webpackChunkName: "component---src-ui-template-page-page-tsxhead" */),
  "component---src-ui-template-post-post-tsx": () => import("./../../../src/ui/template/Post/Post.tsx?export=head" /* webpackChunkName: "component---src-ui-template-post-post-tsxhead" */)
}

