import memoize from 'moize';

import { NEWSLETTER_MODAL_COOKIE_EXPIRATION, NEWSLETTER_MODAL_COOKIE_NAME } from 'constant/cookie';

import { hideModalOnCloseClick } from 'lib/modal';
import { transformCamelCaseKeysToSnakeCase } from 'lib/objectKey';

import { createNewsletterOptIn } from 'service/newsletter';

export const NEWSLETTER_SUBMISSION_AUTO_CLOSE_DELAY = 3600;

export const formatFormData = memoize.deep((data: any) => transformCamelCaseKeysToSnakeCase(data));

export const formatAndCreateNewsletterOptIn = async (formData: any) =>
  await createNewsletterOptIn({ data: formatFormData(formData) });

export const newsletterFormSubmitHandler = async (formData: any, copy: any) => {
  let message, response, status;
  try {
    response = await formatAndCreateNewsletterOptIn(formData);
    if (response?.error) {
      throw new Error(response?.error);
    }
    status = 'success';
    message = copy?.NEWSLETTER_FORM_SUCCESS_MESSAGE_TEXT;
  } catch (err) {
    status = 'error';
    message = copy?.NEWSLETTER_FORM_ERROR_MESSAGE_TEXT;
  }
  return { status, message };
};

export const delayedHideNewsletterAfterSubmission = ({
  cookies,
  shouldHide,
  newsletterModalSeen,
  setHideModal,
  hide,
}: any) => {
  if (shouldHide && !newsletterModalSeen) {
    setTimeout(() => {
      setHideModal(true);
      hideModalOnCloseClick({
        cookies,
        hide,
        modal_cookie_name: NEWSLETTER_MODAL_COOKIE_NAME,
        modal_cookie_expiration: NEWSLETTER_MODAL_COOKIE_EXPIRATION,
      });
    }, NEWSLETTER_SUBMISSION_AUTO_CLOSE_DELAY);
  }
};
