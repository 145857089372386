import { Link as GatsbyLink } from 'gatsby';
import { ReactNode } from 'react';

import { isInternalURL } from 'lib/link';
import { sanitizeUrl, SITE_BASE_DOMAIN } from 'lib/url';

interface Props {
  children?: ReactNode;
  to: string;
  isInternal?: boolean;
  styling?: any;
  onClick?: any;
  [k: string]: any;
}

/* eslint-disable @typescript-eslint/no-empty-function, react/jsx-no-target-blank */
export const Link = ({
  children,
  to,
  isInternal = false,
  styling,
  onClick = () => {},
  ...rest
}: Props) =>
  isInternal || isInternalURL(to) ? (
    <GatsbyLink sx={styling} to={sanitizeUrl(to)} onClick={onClick} {...rest}>
      {children}
    </GatsbyLink>
  ) : (
    <a
      href={to}
      target={to.includes(SITE_BASE_DOMAIN) ? '_self' : '_blank'}
      rel="noopener"
      onClick={onClick}
      sx={styling}
      {...rest}>
      {children}
    </a>
  );

export default Link;
