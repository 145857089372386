import { FlagsProvider } from 'react-feature-flags';

import { featureFlags } from 'constant/feature';
import { AppProvider } from 'context/AppProvider';

import Diagnostic from 'ui/util/Diagnostic';

interface Props {
  children?: any;
}
export const SiteLayoutApp = ({ children }: Props) => (
  <FlagsProvider value={featureFlags}>
    <Diagnostic fps={false} memoize={false} />
    <AppProvider>{children}</AppProvider>
  </FlagsProvider>
);

export default SiteLayoutApp;
