import memoize from 'moize';

export const formatRolesTitle = memoize.deep(
  ({ prefix, suffix }: { prefix: string; suffix: string }) => `${prefix} (${suffix})`
);

export const roleTypeHasRoles = memoize.deep(
  ({ roleItems, filterCriteria }: any) =>
    roleItems
      .map((roleItem: any) => roleItem?.items)
      .reduce((acc: any, arr: any) => [...acc, ...arr], [])
      .filter(filterCriteria)?.length > 0
);
