import { ThemeProvider } from '@theme-ui/theme-provider';
import { cloneElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { AppProvider } from 'context/AppProvider';
import themeConfig from 'style/theme';
import ErrorFallback from 'ui/util/ErrorFallback';

interface Props {
  siteMetadata?: any;
  children?: any;
}
export const SiteLayoutIframe = ({ siteMetadata, children }: Props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <AppProvider>
      <ThemeProvider theme={themeConfig}>{cloneElement(children, { siteMetadata })}</ThemeProvider>
    </AppProvider>
  </ErrorBoundary>
);

export default SiteLayoutIframe;
