export const portalStyle = `
  .overlay-base {
    padding: 1rem;
    position: fixed;
    top: 10px;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    opacity: 1;
    transition-property: background-color, opacity;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999999;
  }
  .overlay-after {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
  }
  .overlay-before {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }
  .content-base {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    margin: 0 auto;
    border: 0;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0%;
    width: 0%;
    opacity: 0;
    background-color: white;
    transition-property: opacity;
    transition-duration: 600ms;
    transition-timing-function: ease-in-out;
  }
  .content-after {
    width: 80vw;
    max-width: 900px;
    max-height: 70vh !important;
    opacity: 1;
  }
  @media (max-width: 48em) {
    .content-after {
      width: 100vw;
      max-height: 100vh !important;
      opacity: 1;
    }
  }
  .content-before {
    width: 0%;
    height: 0%;
    background-color: transparent;
    max-height: 70vh !important;
  }
  @media (max-width: 48em) {
    .content-before {
      max-height: 100vh !important;
    }
  }
`;
