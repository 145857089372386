import { Box } from '@theme-ui/components';
import isEmpty from 'lodash/isEmpty';
import memoize from 'moize';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useClickOutside } from 'react-click-outside-hook';

import { NavSectionConf } from './SiteNavSimple';
import SiteNavSimpleDropDownExpanded from './SiteNavSimpleDropDownExpanded';
import SiteNavSingleLink from './SiteNavSingleLink';

export const isCurrentSection = memoize.deep(
  ({ currentUrl, url = '' }: { currentUrl: string; url?: string }) => currentUrl === url
);

interface Props {
  currentUrl: string;
  navSectionConfig: NavSectionConf;
  linkClickHandler?: any;
  currentSection?: string;
  styling?: any;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const SiteNavSimpleDropDown = ({
  currentUrl,
  navSectionConfig: { url, section, navItems, isButton, triggerModal, description },
  currentSection = '',
  linkClickHandler = () => {},
  styling = {},
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownToggleHandler = (
    event: SyntheticEvent,
    dropdownState?: boolean,
    preventDefaultEventHandling?: boolean
  ) => {
    if (preventDefaultEventHandling) {
      event.preventDefault();
    }
    dropdownState ? setIsOpen(dropdownState) : setIsOpen(!isOpen);
  };

  const [ref, hasClickedOutside] = useClickOutside();

  useEffect(() => {
    if (hasClickedOutside) {
      setIsOpen(false);
    }
  }, [hasClickedOutside]);

  return !isEmpty(section) ? (
    <Box
      ref={ref}
      sx={{
        display: ['flex', 'inline-block'],
        flexDirection: ['column', null],
        height: [isOpen ? 'auto' : '2.5rem', null],
        ...(isButton ? { width: ['100%', 'auto'] } : {}),
        ...styling,
      }}>
      <SiteNavSingleLink
        isButton={isButton}
        triggerModal={triggerModal}
        isCurrentSection={isCurrentSection({ currentUrl: currentSection, url })}
        items={navItems}
        name={section}
        url={url}
        isDropdown={!isEmpty(navItems)}
        isDropdownLabel
        linkClickHandler={() => linkClickHandler(url)}
        dropdownToggleHandler={dropdownToggleHandler}>
        {section}
      </SiteNavSingleLink>
      {navItems && !isEmpty(navItems) && (
        <SiteNavSimpleDropDownExpanded
          description={description}
          isOpen={isOpen}
          navItems={navItems}
          currentUrl={currentUrl}
          dropdownToggleHandler={dropdownToggleHandler}
          linkClickHandler={() => linkClickHandler(url)}
        />
      )}
    </Box>
  ) : null;
};

export default SiteNavSimpleDropDown;
