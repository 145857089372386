import { Box } from '@theme-ui/components';

import { alpha } from 'lib/color';

import MemoizedComponent from 'ui/util/MemoizedComponent';

import { NavSectionConfigItem } from './SiteNavSimple';

import SiteNavSingleLink from './SiteNavSingleLink';

interface Props {
  isOpen?: boolean;
  currentUrl: string;
  navItems: any;
  dropdownToggleHandler: any;
  linkClickHandler?: any;
  description?: string;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const SiteNavSimpleDropDownExpanded = ({
  isOpen = false,
  navItems,
  currentUrl,
  dropdownToggleHandler,
  description,
  linkClickHandler = () => {},
}: Props) => (
  <Box
    sx={(theme: any) => ({
      position: ['relative', 'absolute'],
      marginLeft: [null, 'auto'],
      marginRight: [null, 'auto'],
      display: isOpen ? 'flex' : 'none',
      height: [isOpen ? '100%' : '0%', '16rem'],
      alignContent: [null, 'center'],
      width: ['100%', 'auto'],
      minWidth: [null, '42rem'],
      top: [null, '3.25rem'],
      right: [null, '.5rem'],
      backgroundColor: 'white',
      border: [null, `1px solid ${alpha('elephant', 0.5)(theme)}`],
      ':hover': {
        display: isOpen ? 'flex !important' : 'none !important',
      },
    })}>
    <Box
      sx={{
        display: ['none', 'flex'],
        maxWidth: [null, '16rem'],
        minWidth: [null, '10rem'],
        marginRight: [null, '1rem'],
        alignItems: 'center',
        fontSize: '.9rem',
        paddingLeft: '1.25rem',
        width: 'auto',
        textAlign: 'left',
        lineHeight: '1.75rem',
      }}>
      {description}
    </Box>
    <Box
      sx={{
        display: 'flex',
        columnGap: [null, '0.75rem'],
        marginTop: [null, '1.5rem'],
        marginBottom: [null, '0.5rem'],
        position: 'relative',
        backgroundColor: 'white',
        color: 'elephant',
        lineHeight: '1rem',
        minWidth: [null, '28rem'],
        fontSize: '0.9rem',
        listStyle: 'none',
        zIndex: 1000,
        textAlign: 'left',
        overflow: ['hidden', null],
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        alignContent: 'center',
        margin: 0,
        height: 'auto',
        '*': {
          width: 'auto',
          flex: '1 1 2rem',
          boxSizing: 'border-box',
          flexWrap: 'wrap',
          height: '1.25rem',
          lineHeight: '2',
          display: 'flex',
          alignItems: 'stretch',
        },
      }}
      aria-labelledby="aboutDropdownMenu">
      {navItems.map(({ url, name }: NavSectionConfigItem) => (
        <SiteNavSingleLink
          key={`item-${url}`}
          isDropdown={false}
          isSubNavItem={true}
          url={url}
          name={name}
          bold={true}
          isCurrentUrl={currentUrl === url}
          dropdownToggleHandler={dropdownToggleHandler}
          linkClickHandler={linkClickHandler}
          styling={{
            transition: 'color 100ms ease-in-out, opacity 500ms ease-in-out',
            ':hover': {
              color: 'redOrange',
            },
            marginLeft: [0, null],
            opacity: isOpen ? 1 : 0,
          }}
        />
      ))}
    </Box>
  </Box>
);

export default MemoizedComponent(SiteNavSimpleDropDownExpanded);
