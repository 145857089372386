interface Props {
  children?: any;
  alertIsOpen?: boolean;
}

export const PADDING_TOP_MOBILE_WITH_ALERT = '12rem';
export const PADDING_TOP_MOBILE = '8rem';
export const PADDING_TOP = '10rem';

export const SiteLayoutMain = ({ children, alertIsOpen }: Props) => (
  <main
    id="content"
    role="main"
    sx={{
      transition: `padding 450ms ease-in-out 450ms`,
      paddingTop: [alertIsOpen ? PADDING_TOP_MOBILE_WITH_ALERT : PADDING_TOP_MOBILE, PADDING_TOP],
    }}>
    {children}
  </main>
);

export default SiteLayoutMain;
