import isNull from 'lodash/isNull';
import memoize from 'moize';

export const GRID_COLUMNS = 12;

export const getColumnWidth = memoize.deep((width: number) => `${(width / GRID_COLUMNS) * 100}%`);

export const getResponsiveColumnStyles = memoize.deep((columns: (number | null)[]) => ({
  flex: columns.map((col: number | null) => (isNull(col) ? null : `0 0 ${getColumnWidth(col)}`)),
  maxWidth: columns.map((col: number | null) => (isNull(col) ? null : getColumnWidth(col))),
}));

export const getResponsiveOffsetStyles = memoize.deep((offsets: (number | null)[]) => ({
  marginLeft: offsets.map((col: number | null) => (isNull(col) ? null : getColumnWidth(col))),
}));
