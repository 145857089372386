import { APP_NAME, BUILD_ID, SITE_NAME } from 'constant/env';

export const ANALYTICS_EVENT_ENUM = {
  custom_view_nav_section: 'custom_view_nav_section',
  custom_select_nav_item: 'custom_select_nav_item',
  custom_close_newsletter_modal: 'custom_close_newsletter_modal',
  custom_signup_newsletter_modal: 'custom_signup_newsletter_modal',
};

export const ANALYTICS_EVENT_MAPPING = {
  ...ANALYTICS_EVENT_ENUM,
  app_name: { gtm: 'app_name', ua: 'app_name', value: APP_NAME },
  app_id: { gtm: 'app_id', ua: 'app_id', value: SITE_NAME },
  app_version: { gtm: 'app_version', ua: 'app_version', value: BUILD_ID },
};
