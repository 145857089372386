import { color } from 'style/theme/colors';

import MemoizedComponent from 'ui/util/MemoizedComponent';

import HeaderMobileNav from './HeaderMobileNav';
import HeaderSiteLogo from './HeaderSiteLogo';
import HeaderSiteNav from './HeaderSiteNav';

interface Props {
  siteNav: any;
  currentUrl?: string;
  mobileNavIsOpen?: boolean;
  setMobileNavIsOpen?: any;
  alertIsOpen?: boolean;
}
export const HeaderPrimaryNav = ({
  siteNav,
  currentUrl,
  mobileNavIsOpen,
  setMobileNavIsOpen,
  alertIsOpen = false,
}: Props) => (
  <>
    <HeaderMobileNav
      mobileNavIsOpen={mobileNavIsOpen}
      setMobileNavIsOpen={setMobileNavIsOpen}
      color={color.elephant}
    />
    <HeaderSiteLogo />
    <HeaderSiteNav
      siteNav={siteNav}
      mobileNavIsOpen={mobileNavIsOpen}
      currentUrl={currentUrl}
      alertIsOpen={alertIsOpen}
    />
  </>
);

export default MemoizedComponent(HeaderPrimaryNav);
