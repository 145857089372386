import { lazy, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';

const ContentModule = lazy(() => import('ui/util/ContentModule'));

interface Props {
  footer: { modules?: any };
}

const footerStyles = (inView: boolean): any => ({
  transition: 'opacity 300ms ease-in-out',
  opacity: inView ? 1 : 0,
  backgroundColor: 'light',
  color: 'blue',
  padding: '1rem  0',
  margin: '3rem 0 0 0',
  h6: {
    fontSize: '1rem !important',
    fontWeight: '700 !important',
    marginBottom: '.5rem !important',
    textAlign: 'left !important',
    paddingBottom: '.5rem !important',
  },
  p: {
    fontSize: '0.9rem',
    hyphens: 'auto',
    lineHeight: 1.75,
    marginBottom: '.25rem',
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  'ul li': {
    fontSize: '0.9rem',
    marginBottom: '0.25rem',
  },
  'ul li a': {
    borderBottom: '0 !important',
  },
});

export const Footer = ({ footer: { modules = [] } }: Props) => {
  const [ref, inView] = useInView({ triggerOnce: true });

  const styles = footerStyles(inView);

  return (
    <Suspense fallback={<div />}>
      <footer
        ref={ref}
        sx={{
          ...styles,
          opacity: inView ? 1 : 0,
          transition: 'opacity 300ms ease-in-out',
          paddingX: [null, '4rem'],
        }}
        className="site-footer">
        {modules?.map((module: any, idx: number) => (
          <ContentModule key={`content-module-footer-${idx}`} idx={idx} {...module} />
        ))}
      </footer>
    </Suspense>
  );
};

export default Footer;
