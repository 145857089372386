import color from './colors';

export const BASE_FONT = 16;
export const BASE_LINE_HEIGHT = 1.75;
export const BASELINE = BASE_FONT * BASE_LINE_HEIGHT;

export const typography = {
  baseFontSize: `${BASE_FONT}px`,
  baseLineHeight: BASE_LINE_HEIGHT,
  scaleRatio: 5 / 2,
  headingFontFamily: ['Work Sans, Work Sans-fallback, sans-serif'],
  bodyFontFamily: ['Work Sans, Work Sans-fallback, sans-serif'],
  bodyColor: color.blue,
  headingWeight: 700,
  bodyWeight: 400,
  boldWeight: 700,
};

export default typography;
