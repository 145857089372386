import { Squash as Hamburger } from 'hamburger-react';

import Column from 'ui/component/Column';

interface Props {
  mobileNavIsOpen?: boolean;
  setMobileNavIsOpen?: any;
  color?: string;
}

export const HeaderMobileNav = ({ mobileNavIsOpen, setMobileNavIsOpen, color }: Props) => (
  <Column
    columns={[3, 0]}
    disablePadding={true}
    styling={{
      display: ['block', 'none'],
      '.hamburger-react > div > div': { height: '1px !important' },
      zIndex: ['999999', 'inherit'],
    }}>
    <Hamburger
      toggled={mobileNavIsOpen}
      toggle={setMobileNavIsOpen}
      label="Show menu"
      easing="ease-in"
      color={color}
      size={28}
    />
  </Column>
);

export default HeaderMobileNav;
