export const NEWSLETTER_SIGNUP_API_ENDPOINT = '/api/v1/newsletter-opt-in';

const headers = {
  Accept: `application/json`,
  'Content-Type': `application/json`,
};

export const createNewsletterOptIn = async (data: any) => {
  let response;
  try {
    response = await fetch(NEWSLETTER_SIGNUP_API_ENDPOINT, {
      headers,
      method: 'POST',
      body: JSON.stringify(data),
    });
  } catch (err) {
    throw new Error(`Error in newsletter opt-in: ${err}`);
  }
  return await response?.json();
};
