import { useContext, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';

import { AppContext } from 'context/AppProvider';

import HeaderElement from './HeaderElement';
import HeaderLayout from './HeaderLayout';

interface Props {
  siteNav: any;
  currentUrl?: any;
  alertIsOpen?: boolean;
}

export const Header = ({ currentUrl, siteNav, alertIsOpen = false }: Props) => {
  const { state } = useContext(AppContext);

  const {
    site: { navIsOpen },
  } = useSnapshot(state);

  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  useEffect(() => {
    state.site.navIsOpen = mobileNavIsOpen;
  }, [mobileNavIsOpen]);

  useEffect(() => {
    setMobileNavIsOpen(navIsOpen);
  }, [navIsOpen]);

  return (
    <HeaderElement alertIsOpen={alertIsOpen}>
      <HeaderLayout
        alertIsOpen={alertIsOpen}
        setMobileNavIsOpen={setMobileNavIsOpen}
        mobileNavIsOpen={mobileNavIsOpen}
        siteNav={siteNav}
        currentUrl={currentUrl}
      />
    </HeaderElement>
  );
};

export default Header;
