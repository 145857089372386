export const brand = {
  elephant: '#143757',
  arrowHeadLake: '#5a7389',
  heather: '#b8c3cc',
  greyChateau: '#939ca3',
  lightBrilliantOrange: '#faaf3f',
  alabaster: '#fafafa',
  elm: '#1b8286',
  white: '#ffffff',
  black: '#000000',
  athensGray: '#f0f2f5',
  catskillWhite: '#e4ebf1',
  silver: '#ccc',
  aquaHaze: '#f3f8f7',
  oceanBluePearl: '#5f3cb9',
  redOrange: '#ff5a46',
  tropicalRainForest: '#1b8657',
  geyser: '#d0d7dd',
  firefly: '#0a1d2d',
  ghostWhite: '#f8f8ff',
  blueCharcoal: '#212529',
  mistyAqua: '#BDDBDA',
  steam: '#DDDDDD',
  squant: '#666666',
  lightSalmonPink: '#ff9e93',
  vividTangerine: '#ff8779',
  zenithHeights: '#a9c9c8',
  fountainBlue: '#6ACACE',
};

export const legacyBootstrapPalette = {
  azureRadiance: '#007BFF',
  electricViolet: '#6610F2',
  purpleHeart: '#6F42C1',
  frostBite: '#E83E8C',
  rustyRed: '#DC3545',
  ecstasy: '#FD7E14',
  amber: '#FFC107',
  eucalyptus: '#28A745',
  caribbeanGreen: '#20C997',
  tealBlue: '#17A2B8',
  pearlLusta: '#FCF8E3',
};

export const core = {
  blue: brand.elephant,
  gold: brand.lightBrilliantOrange,
  teal: brand.elm,
  white: brand.white,
  grey: brand.athensGray,
  purple: brand.oceanBluePearl,
  red: brand.redOrange,
  green: brand.tropicalRainForest,
};

export const ui = {
  inactive: brand.silver,
  light: brand.aquaHaze,
  error: legacyBootstrapPalette.rustyRed,
  success: legacyBootstrapPalette.eucalyptus,
};

export const color = {
  ...core,
  ...ui,
  ...brand,
};

export default color;
