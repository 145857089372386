import { useEffect } from 'react';

import { errorHandler } from 'lib/error';

interface Props {
  error: any;
}

export const ErrorFallback = ({ error }: Props) => {
  useEffect(() => {
    errorHandler({ error });
  }, [error]);
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
};

export default ErrorFallback;
