import { createContext, ReactNode } from 'react';

import state from 'store';

export const AppContext = createContext<any>(state);

interface Props {
  children: ReactNode;
  appState?: any;
}

export const AppProvider = ({ children, appState }: Props) => {
  const { Provider } = AppContext;

  return (
    <Provider
      value={{
        state: appState || state,
      }}>
      {children}
    </Provider>
  );
};

export default AppProvider;
