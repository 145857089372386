import { get } from '@theme-ui/css';
import Color from 'color';
import memoize from 'moize';

export const getColor = (theme: any, color: any) => {
  /* eslint-disable no-param-reassign */
  if (typeof color === 'object') {
    color = Array.isArray(color)
      ? color[0]
      : (color as Exclude<typeof color, string & unknown>).__default;
  }

  if (
    process.env.NODE_ENV !== 'production' &&
    color &&
    /^var\(--theme-ui-colors-(.+)\)$/.test(color)
  ) {
    throw new Error(
      'A CSS property was passed to `getColor`. ' +
        '`theme.colors` contains CSS custom properties. ' +
        'Use `theme.rawColors` instead.'
    );
  }

  return get(theme, 'rawColors' in theme ? `rawColors.${color}` : `colors.${color}`, color);
};

export const darken = memoize.deep(
  (color: any, amount: number) => (theme: any) => Color(getColor(theme, color)).darken(amount)
);

export const alpha = memoize.deep(
  (color: any, opacity: number) => (theme: any) => Color(getColor(theme, color)).alpha(opacity)
);
