import { Duration } from 'duration-converter';
import { useContext, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';

import { NEWSLETTER_MODAL_COOKIE_NAME } from 'constant/cookie';
import { AppContext } from 'context/AppProvider';

import { cookies } from 'lib/cookie';
import { checkForModalSeen, shouldHideModal } from 'lib/modal';
import { delayedHideNewsletterAfterSubmission } from 'lib/newsletter';

import NewsletterSignupContainer from './NewsletterSignupContainer';

const NEWSLETTER_MODAL_AUTOLAUNCH_DELAY = new Duration('15 seconds').MilliSeconds;

interface Props {
  newsletter: {
    heading?: string;
    body?: any;
    cta?: string;
  };
}

export const NewsletterSignup = ({ newsletter: { heading = '', body, cta = '' } }: Props) => {
  const [hidden, hide] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('error');
  const [hideModal, setHideModal] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [shouldHide, setShouldHide] = useState(false);

  const { state } = useContext(AppContext);

  const {
    site: { siteModalIsOpen },
  } = useSnapshot(state);

  const newsletterModalSeen = checkForModalSeen({
    cookies,
    modal_cookie_name: NEWSLETTER_MODAL_COOKIE_NAME,
  });

  useEffect(
    () =>
      setShouldHide(
        shouldHideModal({
          modalSeen: newsletterModalSeen,
          hidden,
        })
      ),
    []
  );

  useEffect(() => {
    if (submitStatus === 'success') {
      delayedHideNewsletterAfterSubmission({
        cookies,
        shouldHide: true,
        newsletterModalSeen,
        setHideModal,
        hide,
      });
    }
  }, [submitStatus, hidden, newsletterModalSeen, setHideModal, hide]);

  useEffect(() => {
    if (!siteModalIsOpen) {
      setHideModal(false);
      setTimeout(() => {
        setLoaded(true);
      }, NEWSLETTER_MODAL_AUTOLAUNCH_DELAY);
    }
    if (shouldHide) {
      setLoaded(false);
      setHideModal(true);
    }
  }, [siteModalIsOpen, shouldHide]);

  return (
    <div
      sx={{
        bottom: '0',
        right: [null, null],
        left: ['0', '0'],
        backgroundColor: '#fff',
        position: 'fixed',
        zIndex: 99999999999999,
        opacity: submitStatus !== 'success' && loaded ? 1 : 0,
        height: submitStatus === 'success' ? '80px' : '240px',
        width: ['100vw', '540px'],
        transition: `opacity 600ms ease-in-out 3000ms`,
        flexDirection: 'column',
        display: shouldHide || hideModal || hidden ? 'none' : 'block',
      }}
      role="presentation">
      <NewsletterSignupContainer
        hide={hide}
        heading={heading}
        body={body}
        submitStatus={submitStatus}
        setSubmitStatus={setSubmitStatus}
        cta={cta}
      />
    </div>
  );
};

export default NewsletterSignup;
