import debounce from 'lodash/debounce';
import memoize from 'moize';

import { ANALYTICS_EVENT_MAPPING } from 'constant/analytics';
import { isBrowser } from 'lib/browser';

const TRACKING_DEBOUNCE_DELAY = 250;

export const gtmDataLayer = (args: any) => {
  window.dataLayer = window?.dataLayer || [];
  if (isBrowser) {
    window?.dataLayer.push(args);
  }
};

export const analyticsEvent = ({
  name,
  config,
  value = '',
}: {
  name: string;
  config: any;
  value?: any;
}) => gtmDataLayer({ event: config?.gtm || name, value });

export const formatGTMItem = memoize.deep((item: any, index: number) => ({
  item_id: item?.sku,
  item_name: item?.title,
  currency: item?.priceV2?.currencyCode,
  index,
  price: item?.priceV2?.amount,
  quantity: item?.quantity,
}));

export const track = ({ event, payload }: { event: string; payload?: any }) => {
  const debouncedAnalyticsEvent = debounce(analyticsEvent, TRACKING_DEBOUNCE_DELAY);
  debouncedAnalyticsEvent({
    name: event,
    config: ANALYTICS_EVENT_MAPPING?.[event],
    value: ANALYTICS_EVENT_MAPPING?.[event]?.action
      ? ANALYTICS_EVENT_MAPPING?.[event]?.action(payload)
      : payload,
  });
};
