import { Box } from '@theme-ui/components';

import { lazy, Suspense } from 'react';

import MemoizedComponent from 'ui/util/MemoizedComponent';

const RichText = lazy(() => import('ui/module/RichText'));

interface Props {
  content?: any;
}

export const AlertContent = ({ content }: Props) => (
  <Box
    sx={{
      marginY: '0',
      display: 'flex',
      alignItems: 'center',
    }}>
    <Suspense fallback={<div />}>
      <RichText
        content={content?.html?.markdown ? content?.html?.markdown : content}
        styling={{
          marginY: '0',
          p: { fontSize: '14px', marginY: 0, lineHeight: 1.575, hyphens: 'auto' },
          'p a': {
            marginLeft: '0.25rem',
            color: 'white',
            border: 'none',
            width: ['100%'],
            fontWeight: '700',
            letterSpacing: '.35px',
            fontSize: ['12px'],
            outline: 'none',
            textTransform: 'uppercase',
            textAlign: 'center',
            lineHeight: 1.5,
            userSelect: 'none',
            textDecoration: 'none',
            transition: 'all 0.3s ease-in',
            position: 'relative',
          },
          'p a:hover': {
            borderBottom: 'none',
            color: 'white',
          },
          'p a:after': {
            content: '""',
            borderBottom: '1px solid',
            borderBottomColor: 'white',
            width: '0%',
            position: 'absolute',
            bottom: '-4px',
            left: 0,
            transition: 'width .3s ease-in-out',
          },
          'p a:hover::after': {
            width: '100%',
          },
        }}
      />
    </Suspense>
  </Box>
);

export default MemoizedComponent(AlertContent);
