import { Box } from '@theme-ui/components';

import { SyntheticEvent } from 'react';

import Link from 'ui/util/Link';

import { singleLinkStyleProps } from './SiteNavSingleLink';

const dropdownStylingProps: any = ({ bold = true }: { bold?: boolean }) => ({
  textTransform: 'uppercase',
  color: 'blue',
  fontWeight: ['700', bold ? '700' : '500'],
  lineHeight: '2',
  textDecoration: 'none',
  fontSize: '0.8rem',
  letterSpacing: '1px',
  display: 'block',
  borderBottom: 'none',
  marginLeft: '0.5rem',
  marginTop: [null, '0'],
  marginBottom: ['.75rem', 0],
  '::before': {
    content: '""',
  },
});

export const DynamicLinkElement = ({
  to,
  styling,
  onClick,
  children,
  isDropdownLabel,
  isInternal,
  ...rest
}: {
  isDropdown?: boolean;
  to: any;
  styling?: any;
  onClick?: any;
  children?: any;
  isDropdownLabel?: boolean;
  isInternal?: boolean;
  rest?: any;
}) =>
  isDropdownLabel && !isInternal ? (
    <Box sx={styling} onClick={onClick} {...rest}>
      {children}
    </Box>
  ) : (
    <Link to={to} onClick={onClick} styling={styling} {...rest}>
      {children}
    </Link>
  );

interface Props {
  isCurrentSection?: boolean;
  isDropdown?: boolean;
  isSubNavItem?: boolean;
  url?: string;
  name?: string;
  dropdownToggleHandler?: (
    event: SyntheticEvent,
    state: boolean,
    preventDefaultEventHandling?: boolean,
    currentSection?: string
  ) => void;
  isDropdownLabel?: boolean;
  styling?: any;
  linkClickHandler?: any;
  bold?: boolean;
}

export const SiteNavDropdownLink = ({
  isDropdown = false,
  isSubNavItem = false,
  isDropdownLabel = false,
  url = '#',
  name,
  bold = true,
  /* eslint-disable @typescript-eslint/no-empty-function */
  dropdownToggleHandler = () => {},
  styling = {},
  linkClickHandler = () => {},
}: Props) => (
  <DynamicLinkElement
    isDropdownLabel={isDropdownLabel}
    styling={{
      ...singleLinkStyleProps({ isCurrentSection: false }),
      ...(isSubNavItem || (isDropdown && !isDropdownLabel) ? dropdownStylingProps({ bold }) : {}),
      ...styling,
    }}
    onClick={(event: SyntheticEvent) => {
      if (isSubNavItem) {
        linkClickHandler();
      }
      dropdownToggleHandler(event, false, isDropdownLabel, !isSubNavItem ? url : '');
    }}
    to={url}
    isInternal={!isSubNavItem && isDropdown}>
    {name}
  </DynamicLinkElement>
);

export default SiteNavDropdownLink;
