import memoize from 'moize';

export const formatPostTitle = memoize.deep(
  ({ title, date }: { title: string; date: any }) =>
    `${new Date(date)?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    })} - ${title}`
);

export const formatPostsData = memoize.deep(({ posts }: any) =>
  posts.map(({ date, title, slug, ...post }: any) => ({
    ...post,
    url: slug,
    text: formatPostTitle({ date, title }),
  }))
);

export const getFormattedPostData = memoize.deep(
  ({ heading, content }: { heading: string; content: string }) => [
    {
      sys: {
        contentType: {
          sys: {
            id: 'sectionWithInfoModule',
          },
        },
      },
      infoModules: [
        {
          sys: {
            contentType: {
              sys: {
                id: 'pressReleaseListModule',
              },
            },
          },
          heading,
        },
      ],
      sectionModules: [
        {
          sys: {
            contentType: {
              sys: {
                id: 'richTextModule',
              },
            },
          },
          content,
        },
      ],
    },
  ]
);
