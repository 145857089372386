import { StaticImage } from 'gatsby-plugin-image';
import { useContext } from 'react';

import { AppContext } from 'context/AppProvider';

import Link from 'ui/util/Link';

const IMAGE_LOGO_PATH = '../../../../static/assets/images/projectn95-logo-horz.svg';

export const SiteLogo = () => {
  const { state } = useContext(AppContext);

  const onClickHandler = () => {
    state.site.currentSection = '/';
  };

  return (
    <div
      data-testid="site-logo"
      sx={{
        width: ['120px', '120px'],
        float: ['right', 'none'],
        marginY: 0,
      }}>
      <Link onClick={onClickHandler} to="/" aria-label="Project N95 Home" rel="home">
        <StaticImage
          src={IMAGE_LOGO_PATH}
          title="Project N95"
          alt="Project N95"
          layout="constrained"
        />
      </Link>
    </div>
  );
};

export default SiteLogo;
