import { ClassNames } from '@emotion/core';

import { lazy, Suspense, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSnapshot } from 'valtio';

import { SITE_MODAL_COOKIE_NAME, SITE_MODAL_COOKIE_EXPIRATION } from 'constant/cookie';
import { AppContext } from 'context/AppProvider';
import { track } from 'lib/analytics';
import { cookies } from 'lib/cookie';
import { checkForModalSeen, hideModalOnCloseClick, shouldHideModal } from 'lib/modal';

const SiteModalContent = lazy(() => import('./SiteModalContent'));

import { portalStyle } from './style';

/* eslint-disable jest/require-hook */
Modal.setAppElement('#___gatsby');

const SITE_MODAL_AUTOLAUNCH_DELAY = 3000;

interface Props {
  siteModal: { modules?: any };
  autoLaunch?: boolean;
}

// TODO : fix on-close animation, add a11y, lazy-load components, add siteModal load animation

export const SiteModal = ({ siteModal: { modules = [] }, autoLaunch = false }: Props): any => {
  const { state } = useContext(AppContext);

  const [hideModal, setHideModal] = useState(false);

  const {
    site: { siteModalIsOpen },
  } = useSnapshot(state);

  const toggleSiteModal = () => {
    if (state.site.siteModalIsOpen) {
      hideModalOnCloseClick({
        cookies,
        hide: setHideModal,
        modal_cookie_name: SITE_MODAL_COOKIE_NAME,
        modal_cookie_expiration: SITE_MODAL_COOKIE_EXPIRATION,
      });
      track({ event: 'view_site_modal', payload: { seen: 1 } });
    }

    state.site.siteModalIsOpen = !!!state.site.siteModalIsOpen;
    setHideModal(state.site.siteModalIsOpen);
  };

  const toggleAutoLaunchModal = (launched: boolean) => {
    state.site.siteModalIsOpen = launched;
  };

  const modalSeen = checkForModalSeen({
    cookies,
    modal_cookie_name: SITE_MODAL_COOKIE_NAME,
  });

  const shouldHide =
    autoLaunch &&
    shouldHideModal({
      modalSeen,
      hidden: hideModal,
    });

  useEffect(() => {
    if (autoLaunch) {
      setTimeout(() => toggleAutoLaunchModal(true), SITE_MODAL_AUTOLAUNCH_DELAY);
    }
  }, [autoLaunch]);

  return (
    <div sx={{ display: shouldHide || hideModal ? 'none' : 'block' }}>
      <ClassNames>
        {({ css }: any) => (
          <Modal
            isOpen={siteModalIsOpen && !shouldHide && !hideModal}
            onRequestClose={toggleSiteModal}
            overlayClassName={{
              base: 'overlay-base',
              afterOpen: 'overlay-after',
              beforeClose: 'overlay-before',
            }}
            className={{
              base: 'content-base',
              afterOpen: 'content-after',
              beforeClose: 'content-before',
            }}
            portalClassName={css`
              ${portalStyle}
            `}
            contentLabel="Site Modal">
            <Suspense fallback={<div />}>
              <SiteModalContent toggleSiteModal={toggleSiteModal} modules={modules} />
            </Suspense>
          </Modal>
        )}
      </ClassNames>
    </div>
  );
};

export default SiteModal;
