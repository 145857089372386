import { Box } from '@theme-ui/components';

import { ALERT_DESKTOP_HEIGHT, ALERT_MOBILE_HEIGHT } from 'constant/alert';

import Column from 'ui/component/Column';

import AlertContent from './AlertContent';

interface Props {
  content?: any;
  backgroundColor?: string;
  enabled?: boolean;
}

export const Alert = ({ content, backgroundColor, enabled = false }: Props) => {
  if (!enabled) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        color: 'white',
        backgroundColor: backgroundColor ? backgroundColor : 'lightSalmonPink',
        zIndex: '999999999999999999',
        height: [ALERT_MOBILE_HEIGHT, ALERT_DESKTOP_HEIGHT],
        opacity: 1,
        transition: `all 300ms ease-in 300ms`,
        padding: ['1.5rem 1.65rem 1.25rem 1.65rem', 'unset'],
      }}>
      <Column
        columns={[null, 12]}
        styling={{
          alignContent: 'center',
          justifyContent: 'center',
          display: 'flex',
          opacity: 1,
          transition: `all 150ms ease-in 150ms`,
          paddingX: '0',
        }}>
        <AlertContent content={content} />
      </Column>
    </Box>
  );
};

export default Alert;
