export const featureFlags = [
  {
    name: 'autolaunchModal',
    isActive: String(process.env.GATSBY_FEATURE_FLAG_AUTOLAUNCH_MODAL) === 'true',
  },
  {
    name: 'commerce',
    isActive: String(process.env.GATSBY_FEATURE_FLAG_COMMERCE) === 'true',
    impactsNav: true,
  },
  {
    name: 'search',
    isActive: String(process.env.GATSBY_FEATURE_FLAG_SEARCH) === 'true',
    impactsNav: true,
  },
  { name: 'newsletter', isActive: String(process.env.GATSBY_FEATURE_FLAG_NEWSLETTER) === 'true' },
  {
    name: 'searchcache',
    isActive: String(process.env.GATSBY_FEATURE_FLAG_SEARCH_CACHE) === 'true',
  },
];
