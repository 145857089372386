import { formatRolesTitle } from 'lib/role';

export const getRolesFromData = (data: any) =>
  data.map(({ team, edges }: { team: string; edges: any }) => ({
    heading: team,
    items: edges.map(
      ({
        node: {
          data: { availability, role_description, role_title, paid },
        },
      }: any) => ({
        title: formatRolesTitle({ prefix: role_title, suffix: availability }),
        body: role_description,
        paid,
      })
    ),
  }));
