import { Script } from 'gatsby';

export const Analytics = () => (
  <>
    <Script
      src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_CONTAINER_ID}`}
      strategy="idle"
      forward={[`dataLayer.push`]}
    />
    <Script id="web-vitals-polyfill" strategy="idle">
      {`
        !function(){var e,t,n,i,r={passive:!0,capture:!0},a=new Date,o=function(){i=[],t=-1,e=null,f(addEventListener)},c=function(i,r){e||(e=r,t=i,n=new Date,f(removeEventListener),u())},u=function(){if(t>=0&&t<n-a){var r={entryType:"first-input",name:e.type,target:e.target,cancelable:e.cancelable,startTime:e.timeStamp,processingStart:e.timeStamp+t};i.forEach((function(e){e(r)})),i=[]}},s=function(e){if(e.cancelable){var t=(e.timeStamp>1e12?new Date:performance.now())-e.timeStamp;"pointerdown"==e.type?function(e,t){var n=function(){c(e,t),a()},i=function(){a()},a=function(){removeEventListener("pointerup",n,r),removeEventListener("pointercancel",i,r)};addEventListener("pointerup",n,r),addEventListener("pointercancel",i,r)}(t,e):c(t,e)}},f=function(e){["mousedown","keydown","touchstart","pointerdown"].forEach((function(t){return e(t,s,r)}))},p="hidden"===document.visibilityState?0:1/0;addEventListener("visibilitychange",(function e(t){"hidden"===document.visibilityState&&(p=t.timeStamp,removeEventListener("visibilitychange",e,!0))}),!0);o(),self.webVitals={firstInputPolyfill:function(e){i.push(e),u()},resetFirstInputPolyfill:o,get firstHiddenTime(){return p}}}();
      `}
    </Script>
    <Script id="gtm-init" strategy="idle">
      {`
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })
      `}
    </Script>
  </>
);

export default Analytics;
