import get from 'lodash/get';
import memoize from 'moize';

export const getEdges = memoize.deep(({ data, key }: { data: any; key?: string }) =>
  key
    ? data?.[key]?.edges?.map((edge: any) => edge.node)
    : data?.edges?.map((edge: any) => edge.node)
);

export const getEdgesAndFlatten = memoize.deep(({ data, key }: { data: any; key: string }) =>
  get(getEdges({ data, key }), '[0]', {})
);
