import { proxy } from 'valtio';

export const state = proxy({
  site: {
    cartIsOpen: false,
    searchIsOpen: false,
    siteModalIsOpen: false,
    copy: {},
    footer: {},
    nav: {},
    navIsOpen: false,
    navs: [],
    metadata: {},
    newsletter: {},
    siteModal: {},
    currentSection: '',
    gridMode: 'grid',
  },
  faq: {},
  press: {
    pressReleases: [],
  },
  roles: {
    roleList: [],
  },
  team: {
    teamList: [],
  },
  vettedProducts: [],
  donationProduct: {},
  productAttributeRegistry: [],
});

export default state;
