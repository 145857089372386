import { globalHistory } from '@reach/router';

import moize from 'moize';
import { useCallback, useEffect } from 'react';

import FPSAnalysis from 'ui/util/FPSAnalysis';

moize.collectStats();

interface Props {
  memoize?: boolean;
  fps?: boolean;
}

export const Diagnostic = ({ memoize = true, fps = true }: Props) => {
  const historyWatcher = useCallback(() => {
    if (memoize) {
      // eslint-disable-next-line no-console
      console.log(moize.getStats());
    }
  }, [memoize]);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        historyWatcher();
      }
    });
  }, []);

  return <>{fps && <FPSAnalysis />}</>;
};

export default Diagnostic;
