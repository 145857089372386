import memoize from 'moize';

import { SITE_NAV_ID } from 'constant/nav';
import { flattenKeyValueItems } from 'lib/objectValue';
import { formatPostsData } from 'lib/post';
import { getEdges, getSiteMetadataFromData, getTeamListFromData } from 'lib/selector';

// TODO : refactor to use data mapper

export const getSiteLayoutData = memoize.deep(({ data }: { data: any }) => {
  const footer = data?.contentfulFooter;
  const newsletter = data?.contentfulNewsletterSignup;
  const siteModal = data?.contentfulSiteModal;
  const siteAlert = data?.contentfulAlertModule;
  const roles = data?.allAirtableRoles?.group;
  const vettedProducts = getEdges({ data, key: 'allAirtableProducts' })?.map(
    (products: any) => products?.data
  );
  const pressReleases = formatPostsData({ posts: getEdges({ data, key: 'allContentfulPost' }) });
  const siteCopy = flattenKeyValueItems({
    keyValueItems: data?.contentfulKeyValueListModule?.keyValueItems,
  });

  const siteNavs = getEdges({ data, key: 'allContentfulNav' });
  const siteNav = siteNavs.find(({ navId }: { navId?: string }) => navId === SITE_NAV_ID);
  const siteMetadata = getSiteMetadataFromData(data);
  const team = getTeamListFromData(data);
  const donationProduct = data?.donationProduct;

  const productAttributeRegistry = data?.contentfulImageListModule?.imageItems;

  return {
    donationProduct,
    footer,
    newsletter,
    siteModal,
    siteAlert,
    roles,
    pressReleases,
    siteCopy,
    siteNav,
    siteNavs,
    siteMetadata,
    team,
    vettedProducts,
    productAttributeRegistry,
  };
});

export const setSiteLayoutDataToState = ({ data, state }: { data: any; state: any }) => {
  const {
    donationProduct,
    footer,
    newsletter,
    siteModal,
    siteAlert,
    siteCopy,
    siteNav,
    siteNavs,
    siteMetadata,
    pressReleases,
    roles,
    team,
    vettedProducts,
    productAttributeRegistry,
  } = data;

  state.site.copy = siteCopy;
  state.site.footer = footer;
  state.site.metadata = siteMetadata;
  state.site.nav = siteNav;
  state.site.navs = siteNavs;
  state.site.newsletter = newsletter;
  state.site.siteModal = siteModal;
  state.site.siteAlert = siteAlert;
  state.press.pressReleases = pressReleases;
  state.roles.roleList = roles;
  state.team.teamList = team;
  state.vettedProducts = vettedProducts;
  state.productAttributeRegistry = productAttributeRegistry;
  state.donationProduct = donationProduct;
};

export const setupSiteLayoutData = ({ data, state }: { data: any; state: any }) => {
  const siteLayoutData = getSiteLayoutData({ data });
  setSiteLayoutDataToState({ data: siteLayoutData, state });
  return siteLayoutData;
};
